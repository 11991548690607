<template>
  <main>
    <div class="d-flex align-items-center">
      <div>
        <b-form-file
            plain
            multiple
            size="sm"
            @change="upload">
        </b-form-file>
      </div>
      <div class="ml-2">
        <b-button v-if="file" variant="primary" siz="sm" v-on:click="save">
          <font-awesome-icon :icon="['fa', 'file-upload']" />
        </b-button>
      </div>
    </div>
  </main>
</template>

<script>
import {CognitoIdentityCredentials, config, S3} from "aws-sdk"

export default {
  name: "dataUpload",
  props: {
    adId: String
  },
  data:() => ({
    pending: false,
    file: null,
    progress: 0,
    uploaded: null
  }),

  methods: {
    async upload (e) {
      const {target} = e
      const {files} = target
      const file = files[0]
      const {name} = file

      if (!name.includes('xlsx')) {
        this.$bvToast.toast(`엑셀파일을 업로드하여 주세요.`, {
          title: 'ERROR',
          autoHideDelay: 5000,
          variant: 'danger',
          appendToast: false,
        });
        return
      }
      this.file = file
    },

    async save () {
      if (this.file === null) return this.$bvToast.toast(`엑셀파일을 업로드하여 주세요.`, {
        title: 'ERROR',
        autoHideDelay: 2000,
        variant: 'danger',
        appendToast: false,
      });

      try {
        const {location, fileName, contentType, key, etag, originalName, size} = await this.uploadToS3({file: this.file, key: this.adId, _progress: this.progress})
        this.$bvToast.toast('업로드가 완료되었습니다. 데이터 저장 중 입니다.', {
          title: 'SUCCESS',
          autoHideDelay: 2000,
          variant: 'success',
          appendToast: false,
        })
        this.uploaded = {
          path: location,
          fileName: originalName,
          type: contentType,
          key,
          etag,
          size
        }

        const {data} = await this.axios({
          url: `admin/wide/dataUpload/${this.adId}`,
          method: 'POST',
          data: {
            uploaded: this.uploaded
          }
        })

        const {result, error, insertedCount} = data;

        if (!result) return this.$bvToast.toast(error, {
          title: 'ERROR',
          autoHideDelay: 2000,
          variant: 'danger',
          appendToast: false,
        });

        this.$bvToast.toast('데이터 저장이 완료 되었습니다.', {
          title: 'SUCCESS',
          autoHideDelay: 2000,
          variant: 'success',
          appendToast: false,
        })
        this.$emit('load', this.adId)
      } catch (e) {
        console.error(e)
      }
    },

    async uploadToS3 ({file, key, _progress}) {
      try {
        const url = `/my-ad/cognito`
        const method = `get`
        const {data} = await this.axios({
          url,
          method
        })
        config.update({
          region: data.region,
          credentials: new CognitoIdentityCredentials({
            IdentityPoolId: data.IdentityPoolId
          })
        })
        const s3 = new S3({
          apiVersion: '2006-03-01',
          params: {Bucket: data.Bucket}
        })
        const {Bucket} = data
        const {name, type, size} = file
        const newName = `${this.$uuid.v4()}-${new Date().getTime()}.${name.split('.').pop()}`
        const uploadKey = `wideData/${key}/${newName}`
        const uploadFile = {
          Bucket,
          Key: uploadKey,
          ContentType: type,
          Body: file,
          ACL: 'public-read'
        }

        const upload = (_params) => {
          return new Promise((resolve, reject) => {
            s3.upload(_params, (err, data) => {
              if (err) return reject(err)
              return resolve(data)
            }).on('httpUploadProgress', (progress) => {
              const {loaded, total} = progress
              if (_progress ) _progress = loaded / total * 100
            })
          })
        }
        const result = await upload(uploadFile)
        const {Location, Key, ETag} = result
        return {location: Location, fileName: newName, contentType: type, key: Key, etag: ETag, originalName: name, size}
      } catch (e) {
        throw new Error(e)
      }
    },
  }
}
</script>

<style scoped>

</style>
