<template>
  <b-tr :class="selectedId === _id ? 'active' : ''">
    <b-td>
<!--      <router-link
          :to="link"
      >
        {{ _id }} ({{ seq }})
      </router-link>-->
      <span v-on:click="adDetail(_id)" style="color:blue;">{{ _id }} ({{ seq }})</span>
    </b-td>
    <b-td>
      {{ title }}
    </b-td>
    <b-td>
      {{ new Date(regDate).toLocaleString() }}
    </b-td>
    <b-td>
      <div>
        {{ startDate ? new Date(startDate).toLocaleString() : '' }}
      </div>
      <div>
        {{ completeEndDate ? new Date(completeEndDate).toLocaleString() : ''}}
      </div>
    </b-td>
    <b-td>
      <div class="d-flex align-content-center flex-column align-items-center justify-content-center">
        <pre class="p-0 m-0">{{ userInfo }}</pre>
      </div>
    </b-td>
    <b-td>
      {{ type }}
    </b-td>
  </b-tr>
</template>

<script>
export default {
  name: "wideSpecific",
  props: {
    selectedId: String,
    _id: String,
    type: String,
    seq: Number,
    title: String,
    status: String,
    regDate: String,
    startDate: String,
    completeEndDate: String,
    regUser: {
      userId: String,
      userName: String,
      userEmail: String
    },
    surveyStatus: String,
    page: Number,
    filterData: {
      searchFilter: String,
      searchValue: String
    },
  },
  computed: {
    link () {
      const link = []
      if (this.page) link.push(`page=${this.page}`)
      if (this.filterData) {
        const linkData = Object.entries(this.filterData).filter(([k, v]) => v).map(([k, v]) => `${k}=${v}`)
        link.push(...linkData)
      }
      return `/ad/list/${this._id}` + (link.length ? `?${link.join('&')}` : '')
    },
    userInfo () {
      return Object.values(this.regUser).filter(info => info).join('\r\n')
    }
  },
  data: () => ({
    item: {},
    event: null
  }),
  created() {
    this.item = {
      status: this.status,
      surveyStatus: this.surveyStatus,
    }
  },
  methods: {
    adDetail (adId) {
      this.$emit('adDetail', adId)
    }
  }
}
</script>

<style scoped>
tr.active {
  color: red;
  font-weight: bold;
  background-color: #F5F5F5;
}
tr > td {
  vertical-align: middle;
}
tr:hover {
  font-weight: bold;
  background-color: #f8f9fa;
}
.thumb {
  width: 5rem;
  height: 5rem;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  margin: auto;
}
.thumb:hover {
  opacity: 0.3;
}
</style>
