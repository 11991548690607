<template>
  <b-modal id="register" size="xl" no-close-on-esc no-close-on-backdrop title="WIDE 프로젝트 등록" @close="modalClose"> <!--centered -->
    <div class="d-block text-center">
      <b-overlay :show="pending" opacity="0.5">
      <b-container fluid>
        <b-row class="my-1">
          <b-col sm="2">
            <label>타이틀 :</label>
          </b-col>
          <b-col sm="10">
            <b-form-input placeholder="타이틀" v-model="adInfo.title"></b-form-input>
          </b-col>
        </b-row>
        <hr class="mb-4"/>

        <b-row class="my-1">
          <b-col sm="2">
            <label>타입 :</label>
          </b-col>
          <b-col sm="10">
              <div class="form-check form-check-inline mr-3">
                <input class="form-check-input"
                       type="radio"
                       name="videoImg"
                       id="type_1"
                       value="video"
                       v-model="adInfo.type"
                      :disabled="isUpload">
                <label class="form-check-label" for="type_1">동영상</label>
              </div>
              <div class="form-check form-check-inline mr-3">
                <input class="form-check-input"
                       type="radio"
                       name="videoImg"
                       id="type_2"
                       value="image"
                       v-model="adInfo.type"
                       :disabled="isUpload">
                <label class="form-check-label" for="type_2">이미지</label>
              </div>
          </b-col>
        </b-row>
        <hr class="mb-4"/>

        <b-row class="my-1">
          <b-col sm="2">
            <label> 광고 업종 :</label>
          </b-col>
          <b-col sm="10">
            <div v-if="industryCodes.length">
              <b-row>
                <b-col sm="4" v-for="(i, idx) of industryCodes" v-bind:key="idx">
                  <b-radio v-model="adInfo.industry" :value="i.value" v-on:change="industryQuestions" :disabled="isUpload">{{i.text}}</b-radio>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <hr class="mb-4"/>

        <b-row class="my-1">
          <b-col sm="2">
            <label>응답수 :</label>
          </b-col>
          <b-col sm="10">
            <div v-if="answerCountCodes.length">
              <b-row>
                <b-col sm="2" v-for="(i, idx) of answerCountCodes" v-bind:key="idx" class="pr-0 pl-0">
                  <b-radio v-model="adInfo.answerCount" :value="i.value" v-on:change="adInfo.answerCountEtc=null">{{i.text}}</b-radio>
                </b-col>
                <b-col class="mt-2 ml-4" sm="3">
                  <b-input-group>
                    <template #prepend>
                      <b-radio v-model="adInfo.answerCount" :value="99" class="mr-2 mt-2">기타</b-radio>
                    </template>
                    <template #append>
                      <b-input-group-text>명</b-input-group-text>
                    </template>
                    <b-form-input :disabled="adInfo.answerCount !== 99" v-model="adInfo.answerCountEtc"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
            </div>
          </b-col>
        </b-row>
        <hr class="mb-4"/>

        <b-row class="my-1">
          <b-col sm="2">
            <label>성별 :</label>
          </b-col>
          <b-col sm="10">
            <div v-if="genderCodes.length">
              <div class="form-check form-check-inline mr-3" v-for="(item, idx) of genderCodes" v-bind:key="`genderCodes_${idx}`">
                <input class="form-check-input"
                       type="checkbox"
                       :id="`gender_${idx}`"
                       :value="item.value"
                       :checked="adInfo.gender & item.value"
                       v-on:change="genderChecked">
                <label class="form-check-label" :for="`gender_${idx}`">{{item.text}}</label>
              </div>
            </div>
          </b-col>
        </b-row>
        <hr class="mb-4"/>

        <b-row class="my-1">
          <b-col sm="2">
            <label>연령 :</label>
          </b-col>
          <b-col sm="10">
            <div v-if="ageCodes.length">
              <div class="form-check form-check-inline mr-3" v-for="(item, idx) of ageCodes" v-bind:key="`ageCodes_${idx}`">
                <input class="form-check-input"
                       type="checkbox"
                       :id="`age_${idx}`"
                       :value="item.value"
                       :checked="adInfo.age & item.value"
                       v-on:change="ageChecked">
                <label class="form-check-label" :for="`age_${idx}`">{{item.text}}</label>
              </div>
            </div>
          </b-col>
        </b-row>
        <hr class="mb-4"/>

        <b-row class="my-1">
          <b-col sm="2">
            <label>지역 :</label>
          </b-col>
          <b-col sm="10">
            <div v-if="regionCodes.length">
              <div class="form-check form-check-inline mr-3" v-for="(item, idx) of regionCodes" v-bind:key="`regionCodes_${idx}`">
                <input class="form-check-input"
                       type="radio"
                       name="category"
                       :id="`region_${idx}`"
                       :value="item.value"
                       v-model="adInfo.region">
                <label class="form-check-label" :for="`region_${idx}`">{{item.text}}</label>
              </div>
            </div>
          </b-col>
        </b-row>
        <hr class="mb-4"/>

        <b-row class="my-1">
          <b-col sm="2">
            <label>카테고리 :</label>
          </b-col>
          <b-col sm="10">
            <div v-if="categories">
              <div class="form-check form-check-inline mr-3" v-for="(item, idx) of categories" v-bind:key="`category_${idx}`">
                <input class="form-check-input"
                       type="radio"
                       name="region"
                       :id="`category_${idx}`"
                       :value="item._id"
                       v-model="adInfo.category">
                <label class="form-check-label" :for="`category_${idx}`">{{item.name}}</label>
              </div>
            </div>
          </b-col>
        </b-row>
        <hr class="mb-4"/>

        <b-row class="my-1" v-if="questions.length">
          <b-col sm="2">
            <label>제거 할 문항 : <br><span style="color:red;">필수 아님</span></label>
          </b-col>
          <b-col sm="10">
            <b-row>
              <b-col sm="2" class="form-check form-check-inline" v-for="(item, idx) of questions" v-bind:key="`module_${idx}`">
                <input class="form-check-input"
                       type="checkbox"
                       :id="`module_${idx}`"
                       :value="item.questionNumber"
                       v-model="adInfo.hideModules"
                       :disabled="isUpload">
                <label class="form-check-label" :for="`module_${idx}`">{{item.name}}</label>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <hr class="mb-4"/>
        <b-row class="my-1" v-if="adInfo._id">
          <b-col sm="2">
            <label>데이터 업로드 : </label>
          </b-col>
          <b-col sm="10">
            <div v-if="adInfo.wideUpload">
              데이터 업로드를 완료 하였습니다.
            </div>
            <div v-else class="d-inline-flex justify-content-between">
              <upload :adId="adInfo._id" @load="load"></upload>
            </div>
          </b-col>
        </b-row>
        <hr class="mb-4"/>

        <b-row class="my-1" v-if="adInfo.wideUpload">
          <b-col sm="2">
            <label>Dashboard 링크 : </label>
          </b-col>
          <b-col sm="10">
            <div v-if="dashboardLink">
              {{dashboardLink}}
            </div>
          </b-col>
        </b-row>
      </b-container>
      </b-overlay>
    </div>
    <template #modal-footer>
      <div>
        <b-button variant="success" v-on:click="save" :disabled="pending">{{adInfo._id ? '수정' : '저장'}}</b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import upload from "@/pages/wide/upload";
export default {
  name: "registerModal",
  components: {
    upload
  },
  data:() => ({
    pending: false,
    userObjectId: null,
    adInfo: {
      _id: null,
      title: null,
      category: null,
      answerCount: 2,
      gender: 3,
      age: 6,
      region: 2,
      industry: null,
      industryQuestionId: null,
      hideModules: [],
      type: null,
      answerCountEtc: null,
      wideUpload: null
    },
    industryCodes: [],
    answerCountCodes: [],
    genderCodes: [],
    ageCodes: [],
    regionCodes: [],
    categories: [],
    questions: [],
    dashboardLink: null
  }),

  created () {
    (async() => {
      this.userObjectId = this.$store.getters._id
      await this.load()
    })()
  },

  computed: {
    isUpload () {
      if (this.adInfo.wideUpload) return true
      return false
    }
  },

  methods: {
    async load (adId) {
      this.pending = true
      try {
        const url = adId === undefined ? `my-ad/assessment` : `admin/wide/detail/${adId}`
        const {data} = await this.axios({url})
        const {result, categories, answerCountCodes, genderCodes, ageCodes, industryCodes, regionCodes, adInfo, questions, dashboardLink, error} = data
        if (result) {
          if (adId !== undefined) {
            this.adInfo = adInfo
            this.questions = questions
            this.dashboardLink = dashboardLink
          } else {
            const industries = industryCodes.reduce((a, b) => {
              a.push(...b)
              return a
            },[]).sort((a, b) => Number(a.value) > Number(b.value) ? 1 : -1)
            this.industryCodes = industries
            this.answerCountCodes = answerCountCodes
            this.genderCodes = genderCodes
            this.ageCodes = ageCodes
            this.regionCodes = regionCodes
            this.categories = categories
          }
        } else {
          this.$bvToast.toast(error, {
            title: 'ERROR',
            autoHideDelay: 5000,
            variant: 'danger',
            appendToast: false,
          });
        }
      } catch (e) {}
      this.pending = false
    },

    async industryQuestions () {
      try {
        if (!this.adInfo.industry) return this.$bvToast.toast(`업종을 선택해야 문항을 볼 수 있습니다.`, {
          title: 'ERROR',
          autoHideDelay: 5000,
          variant: 'danger',
          appendToast: false,
        });

        this.adInfo.hideModules = []
        this.adInfo.industryQuestionId = null
        const {data} = await this.axios({
          url: `admin/wide/industryQuestions/${this.adInfo.industry}`
        })
        const {result, questions, industryQuestionId} = data
        if (result) {
          this.questions = questions
          this.adInfo.industryQuestionId = industryQuestionId
        }
      } catch (e) {}
    },


    genderChecked (e) {
      if (e.target.checked) this.adInfo.gender = this.adInfo.gender + Number(e.target.value)
      else this.adInfo.gender = this.adInfo.gender - Number(e.target.value)
    },

    ageChecked (e) {
      if (e.target.checked) this.adInfo.age = this.adInfo.age + Number(e.target.value)
      else this.adInfo.age = this.adInfo.age - Number(e.target.value)
    },

    async save () {
      this.pending = true
      try {
        const method = this.adInfo._id ? 'PUT' : 'POST'
        const {data} = await this.axios({
          url: 'admin/wide/register',
          method,
          data: {
            adInfo: this.adInfo
          }
        })

        const {result, error, insertedId} = data


        if(!result) {
          this.$bvToast.toast(error, {
            title: 'ERROR',
            autoHideDelay: 5000,
            variant: 'danger',
            appendToast: false,
          });
        } else {
          this.$bvToast.toast(this.adInfo._id === null ? '등록되었습니다' : '수정되었습니다.', {
            title: 'SUCCESS',
            autoHideDelay: 3000,
            variant: 'success',
            appendToast: false,
          });

          if (this.adInfo._id === null) this.adInfo._id = insertedId
          await this.load(this.adInfo._id)
        }
      } catch (e) {}
      this.pending = false
    },

    modalClose () {
      this.adInfo = {
        _id: null,
        title: null,
        category: null,
        answerCount: 2,
        gender: 3,
        age: 6,
        region: 2,
        industry: null,
        industryQuestionId: null,
        hideModules: [],
        type: null,
        answerCountEtc: null,
        wideUpload: null
      };
      this.questions = []
      this.$bvModal.hide('register');
      this.$emit('load')
    }
  }
}
</script>

<style scoped>

</style>
